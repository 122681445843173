import React from 'react';
import Img from 'gatsby-image';

const HeaderImage = ({
  fluid,
}) => (
  <Img fluid={fluid} alt="test" title="test" />
);

export default HeaderImage;
