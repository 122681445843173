import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../layouts';
import Content from '../pageComponents/blog/content/Content';
import Contact from '../sharedComponents/contact/Contact';
import '../styles/extras/blogArticle.scss';

const BlogArticleTemplate = ({
  pageContext, data,
}) => {
  const {
    headerImage: {
      src: headerImageSrc,
      alt: headerImageAlt,
      title: headerImageTitle,
    },
    html,
    title,
    date,
    previous,
    next,
    metaDescription,
    readingTime,
  } = pageContext;

  const { fluid } = data.leadImage.childImageSharp;
  const { staticMap } = data;

  return (
    <Layout
      metaTitle={title}
    >
      <Content
        title={title}
        html={html}
        date={date}
        fluid={fluid}
        readingTime={readingTime}
      />
      <Contact staticMap={staticMap} />
    </Layout>
  );
};

export default BlogArticleTemplate;

export const query = graphql`
  query BlogLeadImageQuery($leadImageSrc: String){
    leadImage: file(relativePath: { eq: $leadImageSrc }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    staticMap {
      childFile {
          childImageSharp {
              fluid(maxWidth: 400, maxHeight: 600, quality: 80) {
                  ...GatsbyImageSharpFluid
              }
          }
      }
    }
  }
`;
