import React from 'react';
import { sanitiseHtml, dateFormatted } from '../../../utils/functionsHelper';

import styles from './Content.module.scss';

import HeaderImage from '../headerImage/HeaderImage';

const Content = ({
  title,
  html,
  date,
  fluid,
  readingTime,
}) => {
  const publishDate = new Date(date);
  return (
    <div className={styles.Container}>
      <div className={styles.DateTitle}>
        <p>{dateFormatted(publishDate.toLocaleDateString())} | {readingTime} mins to read</p>
        <h1>{title}</h1>
      </div>
      <div className={styles.Image}>
        <HeaderImage
          fluid={fluid}
        />
      </div>
      <div className={styles.Content}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: sanitiseHtml(html) }} />
      </div>
    </div>
  );
};

export default Content;
