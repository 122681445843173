export const dateFormatted = (reviewDate = '01/01/2020') => {
  let returnDate = '';
  if (reviewDate === 'Present') {
    returnDate = reviewDate;
  } else {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const truncDate = reviewDate.substring(0, 10);
    const splitDate = truncDate.split('/');
    const reviewYear = splitDate[2];
    const reviewMonth = monthNames[splitDate[1] - 1];
    let reviewDay = splitDate[0].substring(0, 2);

    if (reviewDay.substring(0, 1) === 0) {
      reviewDay = reviewDay.substring(1, 2);
    }
    returnDate = `${reviewMonth} ${reviewDay}, ${reviewYear}`;
  }

  return returnDate;
};

export const sanitiseHtml = (raw) => {
  const sanitisedHtml = raw
    .replace(/<pre><code>(.*)<\/code><\/pre>/gs, '$1')
    .replace(/&#x3C;/g, '<');
  return sanitisedHtml;
};

export default {
  dateFormatted,
  sanitiseHtml,
};
